* {
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
}

button {
  height: 100px;
  width: 200px;
}

canvas {
  overflow: hidden;
}

.navButtonContainer {
  position: fixed;
  z-index: 1;
}

.backButton {
  margin-top: 3vh;
  margin-left: 3vw;

  color: #E3CEFF;
}

.fixedButton {
  margin-right: 3vw;
  margin-top: 3vh;

  color: #DFF1FF;
}

.header {
  position: absolute;
  top: 0;
  right: 0;

  z-index: 2;
}

.header h9{
  position: absolute;
  font-size: small;
  margin-left: 0.75vw;
  margin-top: 5.6vh;
  
  color: #DFF1FF;

  pointer-events: none;
}

.windowBoxContainer {
  height: 85vh;
  width: 50vw;
  position: fixed;
  margin: 7.5vh 0vh 15vw 7.5vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  background-color: transparent;
  z-index: 1;

  pointer-events: none;
}

/* Content Windows */
.windowBox {
  height: 100%;
  width: 100%;

  background-color: #DFF1FF;
  border: 3px outset #F5FBFF;
  pointer-events: all;
}


.navBanner {
  height: 3vh;
  margin: 0.3vh;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(35deg, #E3CEFF 50%, #DFF1FF 100%);
  border: 2px outset #F5FBFF;
}


.navBanner h1 {
  width: 48vw;
  margin-left: 0.5vw;

  font-family: sans-serif;
  font-size: 18px;
  text-align: left;
  flex-direction: row;
  color: #4F8AB8;
}

.navBanner button {
  height: 2.7vh;
  width: 2.7vh;

  margin-right: 0.2%;
  padding: 0%;

  text-align: center;

  background-color: #F5FBFF;
  border: 2px outset #F5FBFF;
}

.navBanner button:active {
  border: 2px inset #F5FBFF;
}


/*Content*/
.contentBox {
  height: 80vh;
  margin: 5px;

  background: #F5FBFF;
  border: 2px inset #FFFFFF;

  overflow-y: scroll;
  overflow-x: hidden;

  font-size: large;
}

.imgContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgContainer img{
    margin-top: 3vh;
    margin-bottom: 0.5vh;
    height: 30%;
    width: 30%;
  
    box-shadow:
        -50px -50px 0 -40px #E3CEFF,
        50px 50px 0 -40px #E3CEFF;
}
.imgContainer img {
  margin-top: 3vh;
  margin-bottom: 0.5vh;
  height: 30%;
  width: 30%;

  box-shadow:
    -50px -50px 0 -40px #E3CEFF,
    50px 50px 0 -40px #E3CEFF;
}

.imgContainer .imgHorizontal{
      width: 90%;
}

.imgContainer h4{
  color: #4b515f;  
  font-size: small;
  text-align: center;
  margin-top: 1vh;
}


.contentBox i {
  font-weight: bold;
}

.contentBox a {
  font-weight: bold;
  margin-left: 3vw;
  margin-top: 2vh;
}

.contentBox #email{
  margin-left: 0;
}

.contentBox h1 {
  color: #222222;
  margin: 0;
  margin-bottom: 1vh;
}

.contentBox h2{
  color: #872199;
  margin-left: 1.5vw;
  margin-bottom: 1vh;
}

.contentBox h3 {
  color: #9c27b0;
  margin-left: 1.5vw;
  margin-bottom: 0.1vh;
}

.contentBox p{
  margin-left: 3vw;
  margin-right: 3vw;
}

.contentBox li{
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  margin-bottom: 1vh;
}

/*Loading Screen*/
#loadingScreen {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(35deg, #E3CEFF 35%, #DFF1FF 100%);
  visibility: visible;

  transition: opacity 1s ease-in-out;
  opacity: 1;
}

#loadingScreen.hidden {
  opacity: 0;
}

:root {
  --bg: #BDE0FE;
  --fg: #BDE0FE;
  --primary: #FFC8DD;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

#loader,
#loader:before,
#loader:after {
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

#loader {
  --polygonH: polygon(0% 0%, 100% 0%, 100% 5.55%, 95% 5.55%, 95% 28%, 60% 46%, 60% 54%, 95% 72%, 95% 94.45%, 100% 94.45%, 100% 100%, 0% 100%, 0% 94.45%, 5% 94.45%, 5% 72%, 40% 54%, 40% 46%, 5% 28%, 5% 5.55%, 0% 5.55%);
  animation-name: flip;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(var(--primary) 0.5em, #737a8c55 0.5em 8.5em, var(--primary) 8.5em);
  clip-path: var(--polygonH);
  -webkit-clip-path: var(--polygonH);
  overflow: hidden;
  position: relative;
  top: 35%;
  left: 46%;
  width: 5em;
  height: 9em;
  z-index: 0;
}

.loadingText h1 {
  position: absolute;
  text-align: left;
  top: 60%;
  left: 43%;
  color: white;
}

.loadingText h1:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 2500ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}


@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

#loader:before,
#loader:after {
  animation-timing-function: linear;
  content: "";
  display: block;
  position: absolute;
}

#loader:before {
  --polygonB1: polygon(0% 0%, 100% 0%, 100% 24%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 50% 47%, 0% 24%);
  --polygonB2: polygon(0% 4%, 100% 4%, 100% 24%, 55% 45%, 55% 100%, 55% 100%, 55% 100%, 45% 100%, 45% 100%, 45% 100%, 45% 45%, 0% 24%);
  --polygonB3: polygon(0% 24%, 100% 24%, 100% 24%, 55% 45%, 55% 80%, 100% 100%, 100% 100%, 0% 100%, 0% 100%, 45% 80%, 45% 45%, 0% 24%);
  --polygonB4: polygon(45% 45%, 55% 45%, 55% 45%, 55% 45%, 55% 58%, 100% 76%, 100% 100%, 0% 100%, 0% 76%, 45% 58%, 45% 45%, 45% 45%);
  --polygonB5: polygon(50% 53%, 50% 53%, 50% 53%, 50% 53%, 50% 53%, 100% 76%, 100% 100%, 0% 100%, 0% 76%, 50% 53%, 50% 53%, 50% 53%);
  animation-name: fill;
  background-color: var(--fg);
  background-size: 100% 3.6em;
  clip-path: var(--polygonB1);
  -webkit-clip-path: var(--polygonB1);
  top: 0.5em;
  left: 0.5em;
  width: 4em;
  height: 8em;
  z-index: 1;
}

#loader:after {
  animation-name: glare;
  background:
    linear-gradient(90deg, #0000 0.5em, #0003 0.5em 1.5em, #0000 1.5em 3.5em, #fff3 3.5em 4.5em, #fff0 4.5em 6.5em, #0003 6.5em 7.5em, #0000 7.5em) 0 0 / 100% 0.5em,
    linear-gradient(90deg, #0000 0.75em, #0003 0.75em 1.25em, #0000 1.25em 3.75em, #fff3 3.75em 4.25em, #fff0 4.25em 6.75em, #0003 6.75em 7.25em, #0000 7.25em) 0 0.5em / 100% 8em,
    linear-gradient(90deg, #0000 0.5em, #0003 0.5em 1.5em, #0000 1.5em 3.5em, #fff3 3.5em 4.5em, #fff0 4.5em 6.5em, #0003 6.5em 7.5em, #0000 7.5em) 0 100% / 100% 0.5em;
  background-repeat: repeat-x;
  top: 0;
  left: -3em;
  width: 200%;
  height: 100%;
  z-index: 2;
}

/* Animations */
@keyframes fill {
  from {
    clip-path: var(--polygonB1);
    -webkit-clip-path: var(--polygonB1);
  }

  10% {
    clip-path: var(--polygonB2);
    -webkit-clip-path: var(--polygonB2);
  }

  45% {
    clip-path: var(--polygonB3);
    -webkit-clip-path: var(--polygonB3);
  }

  80% {
    clip-path: var(--polygonB4);
    -webkit-clip-path: var(--polygonB4);
  }

  85%,
  to {
    clip-path: var(--polygonB5);
    -webkit-clip-path: var(--polygonB5);
  }
}

@keyframes glare {

  from,
  90% {
    transform: translateX(0);
  }

  to {
    transform: translateX(3em);
  }
}

@keyframes flip {

  from,
  90% {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}


/*MOBILE*/
@media only screen and (max-width: 768px) {

  html,
  body {
    overflow-x: hidden;
  }

  body {
    position: relative;
  }

  #loader {
    top: 40%;
    left: 43%;
  }

  .loadingText h1 {
    margin-top: 5vh;
    left: 35%;
  }

  .header {
    position: fixed;
    left: 5vw;
    top: 72.5vh;
    width: 100%;

    display: flex;

    justify-content: space-around;

    z-index: 2;
  }

  .backButton {
    font-size: 7vh;
  }

  .fixedButton {
    font-size: 7vh;
    margin-right: 10vw;

  }

  .header h9{
    position: absolute;
    margin-right: 86vw;
  }

  .mobileNavMenuContainer {
    background-color: transparent;
    position: absolute;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    height: 50%;
    width: 100%;
    margin-top: 15vh;

    z-index: 1;
    pointer-events: none;
  }

  .mobileNavMenuContainer button {
    font-size: 34px;
    color: #4F8AB8;
    font-weight: bold;

    height: 8vh;
    width: 80vw;

    background: linear-gradient(35deg, #E3CEFF 50%, #DFF1FF 100%);

    border: 4px outset #F5FBFF;
    border-radius: 5%;

    z-index: 2;
    pointer-events: all;
  }

  .windowBoxContainer {
    height: 99vh;
    width: 96vw;
    position: fixed;
    margin: 0.5vh 2vw;

    z-index: 3;
  }

  /* Content Windows */
  .windowBox {
    height: 84vh;
    width: 100%;
    margin: 0;
    background-color: #DFF1FF;
    border: 3px outset #F5FBFF;
    pointer-events: all;
  }

  .navBanner {
    height: 5vh;
    margin: 0.3vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(35deg, #E3CEFF 50%, #DFF1FF 100%);
    border: 2px outset #F5FBFF;
  }

  .navBanner button {
    height: 4.5vh;
    width: 4.5vh;
    margin-right: 0.5vw;

    font-size: 20px;
    font-weight: bold;
  }

  .navBanner h1 {
    width: 48vw;
    margin-left: 0.5vw;
    margin-right: 35vw;

  }

  /*Content*/
  .contentBox {
    font-size: 16px;

    height: 76vh;
    margin: 5px;

    background: #FFFFFF;
    border: 2px inset #FFFFFF;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .contentBox h1 {
    margin: 0;
  }

  .contentBox img {
    height: 40vh;
    width: auto;
    object-fit: contain;
  }

  .contentBox .imgHorizontal{
    height: auto;
  }
}